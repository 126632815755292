import React from 'react'
import { Container, Card, Row, Col, Image } from 'react-bootstrap'

const ExtracellularVesicles = () => {
  return (
    <div>
      <Row>
        <Col>
          <Row className='justify-content-center align-items-center'>
            {' '}
            <Image
              className='paddedhorizontal photologo'
              src='./images/ExosomeNew.png'
              alt='exosomelogo'
            />
            <h1>Exosomes</h1>
          </Row>

          <Row className='paddedhorizontal'>
            <p style={{ textAlign: 'justify' }}>
              Exosomes, the word commonly used to describe all extracellular
              vesicles, are just one of three total subset of vesicles secreted
              by the cell. They are the smallest in diameter, ranging from about
              30nm to 150nm. These vesicles originate from the inward budding of
              the endosomal membrane, forming a multivesicular body that fuses
              with the cell membrane to release the exosomes stored inside.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Below is a colorized TEM of extracellular vesicles within the
              typical size range of exosomes:{' '}
            </p>
          </Row>
          <Image src='./images/exosome.jpg' alt='exosome' fluid />
        </Col>
        <Col>
          {' '}
          <Row className='justify-content-center align-items-center'>
            {' '}
            <Image
              className='paddedhorizontal photologo'
              src='./images/MicrovesicleNew.png'
              alt='microvesiclelogo'
            />
            <h1>Microvesicles</h1>
          </Row>
          <Row className='paddedhorizontal'>
            <p style={{ textAlign: 'justify' }}>
              Microvesicles are the second largest of the three subsets of
              extracellular vesicles. They range in size from about 150nm to
              1000nm. These vesicles are produced from the outward budding of
              the cell membrane. Different from the exosome, the lipid bilayer
              of these vesicles is composed of that of the cell membrane whereas
              the exosome membrane is composed of that of the endosome.{' '}
            </p>
            <p style={{ textAlign: 'justify' }}>
              Below is a colorized TEM of an extracellular vesicle within the
              typical size range of a microvesicle:{' '}
            </p>
          </Row>
          <Image src='./images/microvesicle.jpg' alt='microvesicle' fluid />
        </Col>
      </Row>
      <Row></Row>
      <Row className='justify-content-center align-items-center'>
        <Image
          className='paddedhorizontal padded photologo'
          src='./images/ApoptoticBody.png'
          alt='apoptoticbody'
        />
        <h1>Apoptotic Bodies</h1>
        <p>
          Apoptotic bodies are the largest of the subset of extracellular
          vescicles. These vesicles are typically over 1000nm in diameter. They
          form during apoptosis in which the cell's cytoskeleton starts to break
          down and large blebs begin to form from the cell's membrane that
          eventually results in apoptotic bodies.
        </p>
      </Row>
    </div>
  )
}

export default ExtracellularVesicles
